import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './screens/Home';
import Contact from './screens/Contact';
import About from './screens/About';
import Primary from './screens/Primary';
import Admissions from './screens/Admissions';
import Proprietor from './screens/Proprietor';
import Gallery from './screens/Gallery';
import Toddler from './screens/Toddler';
import Early from './screens/Early';
import Nursrey from './screens/Nusrey';


class App extends Component {
  render(){
  return (
    <Router>
    <div>
    <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/contact" exact component={Contact} />
    <Route path="/about" exact component={About} />
    <Route path="/primary" exact component={Primary} />
    <Route path="/admissions" exact component={Admissions} />
    <Route path="/gallery" exact component={Gallery} />
    <Route path="/toddlers" exact component={Toddler} />
    <Route path="/early-years" exact component={Early} />
    <Route path="/nursery-and-prenursery" exact component={Nursrey} />
    <Route path="/proprietress" exact component={Proprietor} />
 
    </Switch>
    </div>
  </Router>
  );
}
}

export default App;
