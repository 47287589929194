import React, {Component} from 'react';
import SideBar from './sidebar/SideBar';
import Nav from './header/Nav';
import Footer from './components/Footer';
import axios from 'axios';

class Gallery extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoaded: false,
            images: []
        };
    }

    componentDidMount(){
        axios.get(`https://cobenaschools.com/api/image_request.php`)
          .then(res => {
            // console.warn(res.data);
             this.setState({
                  isLoaded: true,
                  images: res.data
               })
          })
      }


    render(){
        const {isLoaded} = this.state;
        if(!isLoaded){
            return <div>
      <SideBar />
        <Nav style={{position: 'relative', zIndex:3}} />
        <div style={{height:'21vh'}}></div>
        <section style={{position: 'relative', zIndex:1}} >
  <div className="skeleton-container">
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
      Name  </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
    <div className="card-skeleton">
      <div className="animated-background">
        <div className="card-skeleton-img"></div>
        <div className="skel-mask-container">
          <div className="skel-mask skel-mask-1"></div>
          <div className="skel-mask skel-mask-2"></div>
          <div className="skel-mask skel-mask-3"></div>
          <div className="skel-mask skel-mask-4"></div>
          <div className="skel-mask skel-mask-5"></div>
          <div className="skel-mask skel-mask-6"></div>
        </div>
      </div>
    </div>
  </div>
</section>
            </div>;
        }

        else{
            return(
                <div id="App" style={{background:'#333'}}>
                <SideBar />
                <Nav />
                <div style={{height:'21vh'}}></div>
                <h1 className="text-light text-center text-uppercase">Welcome to Cobena Schools <span style={{color:'#fff851'}}>Gallery</span></h1>
                    <div style={{marginTop:'5vh'}} className="gallery">
                        {this.state.images.map(item =>
                        <p key={item.id}>
                            <a target="_blank" rel="noopener noreferrer" href={item.image_file}>
                            <img style={{ width: 243, height: 243}} src={item.image_file} alt={item.image_name} />
                            </a>
                            </p>
                            )}
                    </div>
                    <div style={{height:'21vh'}}></div>
                    <Footer />
                    </div>

            );
        }

    }

}
export default Gallery;
