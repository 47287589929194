import React, { Component } from 'react';
import Nav from './header/Nav';
import SideBar from './sidebar/SideBar';
import Footer from './components/Footer';
import Parallax from './components/Parallax';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFirstAid, faUserPlus, faFileContract, faHandshake, faClinicMedical, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import {Container, Row, Col} from 'react-bootstrap';
import cobena7 from '../assets/images/cobena7.jpg';
import abc from '../assets/images/abc.png';
import cobena from '../assets/images/cobena.png';
import {Link} from 'react-router-dom';

class Admissions extends Component{
    render(){
        return(
            <div id="App">
            <SideBar />
                <Nav />
                 <header class="showcase" style={{height:'60vh', backgroundImage: `url(${cobena7})`}}>
			<div className="showcase-top">
				<img src={cobena} className="img-fluid" alt="Cobena early years school" style={{marginTop:'8vh'}} />
			</div>
			<div className="showcase-content">
				<h1>Admission in<span style={{color:'#fff851'}}> Progress.</span></h1>
			</div>
		</header>
        <div style={{height:'5vh'}}></div>
        <Container>
            <Row>
                <Col sm={4}>
                <img src={abc} className="img-fluid" alt="admission into cobena schools" />
                </Col>
                <Col sm={8}>
                    <h2 className="text-danger">Admission Procedures</h2>
                    <p>There are several forms that must be completed and in our possession before we
                        can assume the responsibility of caring for your child - NO EXCEPTIONS. This is to ensure
                        that your child will get the very best care from us.
                    </p>
                    <p>These forms are:</p>
                    <ul class="list-group text-light">
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Application Form</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faUserPlus }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Parent Contract</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faFileContract }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Enrollment Agreement</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faHandshake }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Medical Form</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faClinicMedical }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Parent Authorization for the Administration of Medication</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faFirstAid }/></span>
                </li>
                </ul>
                </Col>
            </Row>
            <div style={{height:'5vh'}}></div>
            <p>If you have any questions regarding the completion of these forms, please feel free to contact us on <a className="text-danger" href="tel:+2348129829894">+2348129829894</a> {/*Or <a className="text-danger" href="tel:+2348169609811">08169609811</a> */}</p>
            <div style={{height:'5vh'}}></div>
            <h2>Tuition / Payment Procedures</h2>
            <p>
                Specific rates are outlined in your Enrollment Agreement. Tuition is payable in advance and is due no later than first week of the term, unless another agreement has been agreed upon.
                Payment should be made by bank draft, transfers or cash deposit into school account.
            </p>
            <div style={{height:'5vh'}}></div>
            <h2>Discounts</h2>
            <ul>
                <li><p>A 5% discount is given to every parent / pupil  who wishes to pay the tuition fee for the session (1st to 3rd term) at once.</p></li>
                <li><p>A 5% discount on tuition fee is given on every 2nd child / pupil brought to the school by a parent.</p></li>
                <li><p>A 5% discount on tuition fee is given on every 3rd child / pupil brought to the school by the school by a parent.</p></li>
                <li><p>A 7.5% discount on tuition fee is given on every 4th child / pupil brought to the school by a parent.</p></li>
            </ul>
            <div style={{height:'5vh'}}></div>
            <h2 className="text-danger">Hours Of Operation</h2>
            <p>
                Normal hours of operation are Mondays through Fridays from 7:30 AM - 5:00 PM. Good communication is vital to us.
                It is important that we discuss openly any concerns or questions that may arise. It is also important that there is a similar child philosophy between the parent and us.
            </p>
            <Link className="btn-alt"  to="./docs/registration.pdf" target="_blank"
					>Download Registration Form <FontAwesomeIcon icon = { faFileDownload }/>
				</Link>
        </Container>
        <Parallax />
        <Footer />
        </div>
        )
    }
}

export default Admissions;
