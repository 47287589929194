import React, { Component } from 'react';
import Nav from './header/Nav';
import SideBar from './sidebar/SideBar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fab, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import cobena from '../assets/images/cobena.png';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(fab, faWhatsapp);
const recaptchaRef = React.createRef();

class Contact extends Component{
    state = {
        name: '',
        email: '',
        message: '',
        position: 0,
      }

      handleFormSubmit( event ) {
        event.preventDefault();

        let formData = new FormData();
        formData.append('name', this.state.name)
        formData.append('email', this.state.email)
        formData.append('message', this.state.message)

        if(this.state.name === ''){
          Swal.fire({
            title: 'Empty field',
            text: 'Please enter your name.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }else if(this.state.email === ''){
          Swal.fire({
            title: 'Empty field',
            text: 'Please enter your email.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }else if(this.state.message === ''){
          Swal.fire({
            title: 'Empty field',
            text: 'Please write us a message.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }else{
        axios({
            method: 'post',
            url: 'https://cobenaschools.com/api/contact-requests.php',
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
           if(response.status === 200){
            Swal.fire({
                title: 'Success!',
                text: 'Message sent successfully',
                icon: 'success',
                confirmButtonText: 'OK'
              })
              .then(function() {
                // Redirect the user
                window.location.href = "https://cobenaschools.com";
                });
          }else{
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
          }
        })
        .catch(function (err) {
            // handle error
            Swal.fire({
                title: 'Error!',
                text: {err},
                icon: 'error',
                confirmButtonText: 'OK'
              })
        });
    }
    }

    onSubmit = () => {
      const recaptchaValue = recaptchaRef.current.getValue();
      this.props.onSubmit(recaptchaValue);
    }

      render(){
      return (
        <div id="App">
        <SideBar />
            <Nav />
        <div id="wrapper">
        <div id="left">
          <div id="signin">
            <div className="logo">
              <img src={cobena} style={{width:'11vh', marginTop:'3vh'}} alt="Codeflare" />
            </div>
            <form onSubmit={this.onSubmit}>
              <div>
                <label>Full name</label>
                <input type="text" name="name" className="text-input" value={this.state.name}
                 onChange={e => this.setState({ name: e.target.value })}/>
              </div>
              <div>
                <label>Email</label>
                <input type="email" name="email" className="text-input" value={this.state.email}
                 onChange={e => this.setState({ email: e.target.value })}/>
              </div>
              <div>
                <label>Message</label>
                <textarea rows="5" name="message" className="text-input" value={this.state.message}
                 onChange={e => this.setState({ message: e.target.value })}></textarea>
              </div>

            <center>
              <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Le3g9oZAAAAAL5cg8wsBpG83QEvsgPjrWbUEVMJ"
              />
            </center>
        <br/>

              <button type="submit" className="primary-btn"
               onClick={e => this.handleFormSubmit(e)}>
                 Contact us
                 </button>
            </form>
            <h3 style={{marginTop: '-2rem', color:'#cc5500'}}><FontAwesomeIcon icon = { faEnvelope }/> <a href="mail:info@cobenaschools.com">info@cobenaschools.com</a></h3><br />

            <h3 id="cont" style={{marginTop: '-1rem', color:'#cc5500'}}><FontAwesomeIcon icon = { faPhone }/> <a href="tel:+2348129829894">+2348129829894</a>  <br /> {/*<FontAwesomeIcon icon={["fab", "whatsapp"]} />  <a href="tel:+2348169609811">08169609811</a> */}</h3>

            <div class="or">
              <hr class="bar" />
              <span>OR VISIT US</span>
              <hr class="bar" />
            </div>
            <address style={{textAlign:'center', marginTop:'-2.5vh'}}>
            Plot 720, 692 B Close Satumari Street, Off 69 Road, Gwarinpa II Estate, Abuja.
           </address>
          </div>
        </div>
        <div id="right">
          <div id="showcase">
            <div class="showcase-content">
              <h1 class="showcase-text">
               Children Need Models Rather Than <strong>Critics.</strong>
              </h1>
              <h1>Let's Show You <span style={{color:'#cc5500'}}>How</span> </h1>
              <Link to="/admissions" class="secondary-btn">ENROLL YOUR CHILD NOW</Link>

            </div>
          </div>
        </div>
      </div>
      </div>
      );
    }
    }

export default Contact;
