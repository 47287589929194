import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
 

function Nav(){
    return(
    <div>
 <div className="text-light shadow"   id="header">
</div>
</div>
    )
}

export default Nav;