import React, {Component} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import cobena from '../../assets/images/cobena.png'
import {Link} from 'react-router-dom';

class Header extends Component{
    render(){
        return(
            <div>
                 <header class="showcase">
			<div className="showcase-top">
				<img src={cobena} alt="Cobena school student" style={{marginTop:'8vh'}} />
			</div>
			<div className="showcase-content">
				<h1>Learning never exhausts <br />the <span style={{color:'#fff851'}}>mind.</span></h1>
				<p>Cr&#x000E9;che. Early years. Primary.</p>
				<Link id="enroll-big" to="/admissions" className="btn btn-xl"
					>Enroll Your Child Now <FontAwesomeIcon icon = { faChevronRight }/>
				</Link>
				<Link id="enroll-small" to="/admissions" className="btn btn-xl"
					>Enroll Now <FontAwesomeIcon icon = { faChevronRight }/>  
				</Link>
			</div>
		</header>
            </div>
        )
    }
}

export default Header;