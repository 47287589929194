import React, { Component } from 'react';
import Nav from './header/Nav';
import SideBar from './sidebar/SideBar';
import Footer from '../screens/components/Footer';
import Parallax from '../screens/components/Parallax';
import {Container, Row, Col} from 'react-bootstrap';
import pre from '../assets/images/pre.jpg';
import cobena from '../assets/images/cobena.png';
import alphabet from '../assets/images/alphabet.png';

class Nursrey extends Component{
    
    // componentDidMount(){
    // const canvas = this.refs.canvas;
    // const ctx = canvas.getContext('2d');

    // ctx.fillStyle = "#cc5500";
    // ctx.fillRect(20, 20, 150, 50);
    // ctx.font = "20px Comic Sans MS"
    // ctx.strokeText("Hello", 50, 50)
    //     }
     

    render(){ 
        return(
            <div id="App">
            <SideBar />
                <Nav />
                 <header class="showcase" style={{height:'60vh', backgroundImage: `url(${pre})`}}>
			<div className="showcase-top">
				<img src={cobena} className="img-fluid" alt="Cobena early years school" style={{marginTop:'8vh'}} />
			</div>
			<div className="showcase-content">
				<h1>Nursery And<span style={{color:'#fff851'}}> Pre-Nursery.</span></h1>
			</div>
		</header>
        <div style={{height:'5vh'}}></div>
        <Container>
                <h1 className="text-danger text-center">Pre-Nursery & Nursery </h1>
                <center><img src={alphabet} style={{width:'20vh'}} alt="toddler schools in gwarinpa abuja" /></center>
                <div style={{height:'5vh'}}></div>
                <p>
                   The children progress from playgroup to pre-nursery, to nursery, exploring art materials and experiencing music, dance
                   and drama.
                </p>
                <p>
                    Our caring and experienced teachers reinforce needed developmental objectives through creative art activities designed to build relevant
                    skills and these will ignite the passion for learning. Since children are naturally curious and love to expolre the world around them, 
                    we have designed our classrooms to enrich their learning experiences.
                </p>
                <p>
                    All these activities and more are focused to develop balance, hand/eye co-ordination, participation in creative movements to music and more. 
                    Your child will love our outdoor play areas that are designed for nature study, climbing and sliding.
                </p>
                </Container>
                <div style={{height:'5vh'}}></div>
                <Container>
                <Row>
                    <Col sm={4}>
                        <img src={pre} className="img-fluid" alt="Pre-nursery schools in abuja" />
                    </Col>
                    <Col sm={8}>
                <h1 className="text-danger">Reception</h1>
                <p>
                    This group gets to build on all the knowledge and skills of the previous years, and most importantly, start off
                    on a Guided Reading Program where emergent readers finally become confident and independent readers.
                </p>
                <p>
                    Children in this class focus on developing effective reading skills. They begin to blend more words, read high frequency
                    and tricky words, and write simple sentences.
                </p>
                <p>
                    They learn to tell time, work with weights and measures, do simple additions and subtractions, use a number line and learn to 
                    identify and write more numbers.
                </p>
                </Col>
                </Row>
                </Container>
                <Parallax />
                <Footer />
            </div>
        )
    }
}

export default Nursrey;