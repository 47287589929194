import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import toddler_happy from '../../assets/images/toddlers-happy.jpeg';

class NursreyAd extends Component{
    render(){
        return(
            <div>
                  <div style={{marginTop:'13vh'}}></div>
                <Container>
                <Row>
                <Col sm={4}>
                    <img src={ toddler_happy} className="img-fluid" alt="toddler schools in abuja" />
                </Col>
                <Col sm={8}>
                    <h2>Pre-nursery / Nursery</h2>
                    <p>
                    Our caring and experienced teachers reinforce needed developmental objectives through creative art activities designed to build relevant
                    skills, and these will ignite the passion for learning. ... <br />
                    <Link to="/early-years" className="btn-alt">Continue Reading ...</Link>
                </p>
                </Col>
            </Row>
                </Container>
            </div>
        )
    }
}

export default NursreyAd;
