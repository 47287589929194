import React, {Component} from 'react';
import cobena from '../../assets/videos/student.mp4';
// import kid from '../../assets/images/kid.jpg';

class Tour extends Component{
    render(){
        return(
            <div>
                 <h2 style={{textAlign:'center', marginTop:'20vh'}}>Take A <span>Tour?</span></h2><br />

            <div class="o-video">
            <video style={{ height: 600}} src={cobena}  controls="controls"   />
          </div>
          </div>
        )
    }
}

export default Tour;
