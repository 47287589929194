import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

function Parallax(){
    return(
        <div className="banner-example" style={{marginTop:'16vh'}}>
            <h2 className="text-center">Visit Our Gallery</h2>
        <div id="banner">
          <div className="active bannerItem">
            <span className="banner-header display-1">COBENA SCHOOLS</span>
            <div className="banner-text">Knowledge And Character</div><br />
            <Link to="/gallery" className="btn-alt text-bold"  >VISIT GALLERY</Link>
          </div>
        </div>
      </div>
      
    )
}

export default Parallax;