import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { slide as Menu } from 'react-burger-menu';
import '../../App.css';
import cobena from '../../assets/images/cobena.png';
import {faGraduationCap, faHome, faCameraRetro, faHouseUser, faEnvelope, faUserPlus, faBabyCarriage, faChalkboardTeacher, faRssSquare} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink as Link } from 'react-router-hash-link';
 
export default props => {
  return (
    <Menu>
        <div id="menuImg">
        <img src={cobena} id="cobImg" className="mx-auto d-block img-fluid" style={{width:'50%', alignSelf:'center',  height:'auto' }} alt="Cobena schools"/>
        </div>
         
       
      <Link to="/" className="menu-item">
      <FontAwesomeIcon icon = { faHome }/><span id="span"></span>Home
      </Link>

      <Link to="/about" className="menu-item" href="/about">
      <FontAwesomeIcon icon = { faHouseUser }/><span id="span"></span>About us
      </Link>

      <Link to="/proprietress" className="menu-item">
      <FontAwesomeIcon icon = { faChalkboardTeacher }/><span id="span"></span>Welcome
      </Link>

      <Link to="/admissions" className="menu-item">
      <FontAwesomeIcon icon = { faUserPlus }/><span id="span"></span>Admissions
      </Link>

      <Link to="/early-years" className="menu-item">
      <FontAwesomeIcon icon = { faBabyCarriage }/><span id="span"></span>Early Years
      </Link>

      <Link to="/primary" className="menu-item">
      <FontAwesomeIcon icon = { faGraduationCap }/><span id="span"></span>Primary
      </Link>

      <Link to="/gallery" className="menu-item">
      <FontAwesomeIcon icon = { faCameraRetro }/><span id="span"></span>Gallery
      </Link>

      <Link to="/contact" className="menu-item">
      <FontAwesomeIcon icon = { faEnvelope }/><span id="span"></span>Contact us
      </Link>

      <a target="_blank" rel="noopener noreferrer" href="https://cobenaschools.com/blog" className="menu-item">
      <FontAwesomeIcon icon = { faRssSquare }/><span id="span"></span>Blog
      </a>

    </Menu>
  );
};