import React, {Component} from 'react';
// import founder from '../../assets/images/founder.png';
import { Link } from 'react-router-dom';
import VideoPlayer from 'react-video-js-player';


class Welcome extends Component{

  player = {}
    state = {
        video: {
            src: "https://cobenaschools.com/api/video/welcome.mp4",
            poster: "https://cobenaschools.com/api/video/cobena.jpg"
        }
    }

    onPlayerReady(player){
        this.player = player;
    }

    render(){
        return(
            <div style={{marginTop:'8vh', marginBottom:'8vh'}}>
            <div className="container">
        		<div className="row">
        		<div className="col-lg-12 welcome" style={{ textAlign: 'center' }}>
        			<h2 className="page-header welcome">Welcome To Cobena Schools<br />
                    </h2>
                    <p className="author-box-intro text-small text-dark">A message from the Head of School.
        		</p>
        		</div>
        		</div>
        		</div>

	<section className="author-box container" style={{ marginTop: 27}}>
	<div className="panel panel-default">
   <div className="panel-body">
	<div className="row">
		{/* <div className="col-xs-6 col-xs-offset-3 col-md-3 col-md-offset-0">
<img className="img-fluid img-circle center-block" src={founder} alt="Lady Uche Okoye Cobena schools" />
</div>  */}
			<div className="col-xs-12 col-sm-12 col-md-6">
			{/*	<hr className="visible-xs-block" /> */}
				 <div className="author-inline-block">
				{/* <h4>
				Proprietress
				</h4>	 */}
				</div>
				<div className="author-box-content">
				<p>
                I personally welcome you to <strong>COBENA SCHOOLS</strong> with great pleasure and appreciation.<br /><br />
                Thank you for trusting us to nurture and care for your precious little child(ren).<br />
                We are pleased to have you as part of the COBENA family. ... <br />
				</p>
        <p><a className="text-primary" download href="../../assets/docs/newsletter.pdf">Download Newsletter</a></p>
                <Link to="/proprietress" className="btn-alt" style={{marginBottom: 27}}>Continue reading ...</Link>

				</div>
			</div>
      <div className="col-lg-6">
      <VideoPlayer
        controls={true}
        src={this.state.video.src}
        poster={this.state.video.poster}
        width="450"
        height="450"
        onReady={this.onPlayerReady.bind(this)}
      />
      </div>

		</div>
		</div>
	</div>
</section>
</div>



        )
    }
}

export default Welcome;
