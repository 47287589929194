import React, {Component} from 'react';
import balloon from '../../assets/images/balloon.jpg';
import vision from '../../assets/images/vision.jpg';
import kindergarten from '../../assets/images/kindergarten.png';
import {Link} from 'react-router-dom';

class VisionMisson extends Component{
    render(){
      return(
  <div className="container" style={{marginTop:'8vh'}}>
  <h2 style={{textAlign:'center', position:'relative', top:'6vh'}}>What We Stand For</h2>
  <div className="fancy-cards">
  <div className="fancy-card">
    <div className="top"
    style={{backgroundImage: `url(${vision})`}}>
      <div className="caption">
        <h3 className="title">Our Vision</h3>
        <Link to="/about" className="button">Find Out </Link>
      </div>
    </div>
    <div className="middle"></div>
    <div className="bottom"></div>
  </div>
  <div className="fancy-card">
    <div className="top"
    style={{backgroundImage: `url(${balloon})`}}>
      <div className="caption">
        <h3 className="title">Our Mission</h3>
        <Link to="/about" className="button">Find Out </Link>
      </div>
    </div>
    <div className="middle"></div>
    <div className="bottom"></div>
  </div>
  <div className="fancy-card">
    <div className="top"
    style={{backgroundImage: `url(${kindergarten})`}}>
      <div className="caption">
        <h3 className="title">Admissions</h3>
        <Link to="/admissions" className="button">Find Out </Link>
      </div>
    </div>
    <div className="middle"></div>
    <div className="bottom"></div>
  </div>
</div>
</div>
        )
    }
}

export default VisionMisson;