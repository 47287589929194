import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import creche from '../../assets/images/cobena3.jpg';

class Creche extends Component{
    render(){
        return(
            <div>
                  <div style={{marginTop:'13vh'}}></div>
                <Container>
                <Row>
                <Col sm={4}>
                    <img src={creche} style={{width:'45vh'}} className="img-fluid" alt="creche schools in abuja" />
                </Col>
                <Col sm={8}>
                    <h2>Cr&#x000E9;che And Day Care</h2>
                    <p>
                    Our cr&#x000E9;che is designed like a home to shape your child's mind with various activities using age
                    appropriate resources, with professional caregivers to care for your child. ...
                </p>
                <Link to="/early-years" className="btn-alt">Continue Reading ...</Link>
                </Col>
            </Row>
                </Container>
            </div>
        )
    }
}

export default Creche;
