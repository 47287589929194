import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SideBar from './sidebar/SideBar';
import Footer from './components/Footer';
import Nav from './header/Nav';
import Parallax from './components/Parallax'
import SchoolAd from './components/SchoolAd';
import cobena from '../assets/images/cobena.png';
import cobena5 from '../assets/images/cobena5.jpg'
import vision from '../assets/images/vision.jpg'
import balloon from '../assets/images/balloon.jpg'
import strategy from '../assets/images/strategy.png'

class About extends Component{
    render(){
        return(
            <div id="App">
            <SideBar />
                <Nav />
                 <header class="showcase" style={{height:'60vh', backgroundImage: `url(${cobena5})`}}>
			<div className="showcase-top">
				<img src={cobena} alt="Cobena school student" style={{marginTop:'8vh'}} />
			</div>
			<div className="showcase-content">
				<h1>We Help Pupils Cultivate A Life-time Love For <span style={{color:'#fff851'}}>Learning.</span></h1>
			</div>
		</header>
        <div style={{height:'5vh'}}></div>
        <Container>
            <h2>About Us</h2>
            <p>Cobena schools is established to meet the needs of your children.
                Each child is unique and in recognition of that, we at Cobena aim to 
                lovingly and skillfully assist each child to become a masterpiece.
            </p>
            <p>Here, we partner with parents to give our children the right support they need
                to achieve their full potential.
            </p>
            <div style={{height:'5vh'}}></div>
            <Row>
            <Col sm={4}>
                <img src={vision} alt="Cobena's vision statement" />
            </Col>
            <Col sm={8}>
                <h2 className="text-danger">Our Vision</h2>
                <p>To be an exemplary Godly school where children excel academically and in character.</p>
            </Col>
             </Row>
             <div style={{height:'5vh'}}></div>
             <Row>
             <Col sm={8}>
                <h2 className="text-danger">Our Mission</h2>
                <p>To cultivate a life-time love for learning, high moral standard, excellence and academic pursuit.</p>
            </Col>
            <Col sm={4}>
                <img src={balloon} alt="Cobena's mission statement" />
            </Col>
            </Row>
            <div style={{height:'13vh'}}></div>
            <Row>
                <Col sm={4}>
                <img src={strategy} alt="Cobena's strategy" />
                </Col>
                <Col sm={8}>
                <h2 className="text-danger strive">We Strive to do the following:</h2>
                <ul class="list-group">
                <li class="listing list-group-item d-flex justify-content-between align-items-center font-weight-bold" aria-disabled="true">Provision of a well-prepared, clean, safe, comfortable,
                loving, learning, home-like environment.</li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center font-weight-bold">Teachers working with the children as individuals.</li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center font-weight-bold">Offering lessons to children when they are developmentally ready.</li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center font-weight-bold">Monitoring each child individually until he/she develops a sense of mastery and accomplishment.</li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center font-weight-bold">Integrating the educational philosophy of Dr. Maria Montessori.</li>
                </ul>
                </Col>
            </Row>
            </Container>
            <div style={{height:'5vh'}}></div>
            <Parallax />
            <SchoolAd />
            <div style={{height:'5vh'}}></div>
            <Footer />
            </div>

             
        )
    }
}

export default About;