import React, {Component} from 'react';
import SideBar from './sidebar/SideBar';
import Nav from './header/Nav';
import Header from './components/Header';
import Footer from './components/Footer';
import Welcome from './components/Welcome';
import Tour from './components/Tour';
import VisionMisson from './components/VisionMission';
import Parallax from './components/Parallax';
import AdColumn from './components/AdColumn';
import Enroll from './components/Enroll';
import Creche from './components/Creche';
import SchoolAd from './components/SchoolAd';
import ClassBreakDown from './components/ClassBreakDown';
import NursreyAd from './components/NursreyAd';
import Testimonials from './components/Testimonials';
// import SchoolEnrollPoster from './components/SchoolEnrollPoster';


class Home extends Component{
    render(){
        return(
            <div id="App">
            <SideBar />
                <Nav />
                <Header />
                <Welcome />
                <AdColumn />
                <Creche />
                <NursreyAd />
                <Enroll />
                <VisionMisson />
                <div style={{marginTop:'3vh'}}></div>
                <SchoolAd />
                <div style={{marginTop:'3vh'}}></div>
                <ClassBreakDown />
                <Testimonials />
                <Tour />
                <Parallax />
                <Footer />

              </div>

        )
    }
}

export default Home;
