import React, {Component} from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2';
import axios from 'axios';
library.add(fab, faFacebook, faTwitter, faInstagram);

class Footer extends Component{
    state = {
        email_: '',
      }

      handleSubscribe(event) {
        event.preventDefault();

        let formData = new FormData();

        formData.append('email', this.state.email_);


        if(this.state.email_ === ''){
            Swal.fire({
                title: 'Empty email field',
                text: 'Please enter your email address',
                icon: 'error',
                confirmButtonText: 'OK'
              });
        } else {

        axios({
            method: 'post',
            url: 'https://cobenaschools.com/api/subscribe.php',
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
           if(response.status === 200){
            Swal.fire({
                title: 'Success!',
                text: 'Message sent successfully',
                icon: 'success',
                confirmButtonText: 'OK'
              })
              .then(function() {
                // Redirect the user
                window.location.href = "/";
                });
          }else{
            Swal.fire({
                title: 'Error!',
                text: 'An error occurred',
                icon: 'error',
                confirmButtonText: 'OK'
              });
          }
        })
        .catch(function (err) {
            //handle error
            Swal.fire({
                title: 'Error!',
                text: err,
                icon: 'error',
                confirmButtonText: 'OK'
              })
        });
    }
    }


    render(){
        return(
            <div>

<footer className="new_footer_area bg_color">
            <div className="new_footer_top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s" style={{Visibility: 'visible', AnimationDelay: '0.2s', AnimationName: 'fadeInLeft'}}>
                                <h3 className="f-title f_600 t_color f_size_18">Get in Touch</h3>
                                <p>Don’t miss any updates and newsletters</p>
                                <form className="f_subscribe_two mailchimp">
                                    <input type="email" className="form-control memail" placeholder="Email" value={this.state.email_}
                                    onChange={e => this.setState({ email_: e.target.value })}/>
                                    <button className="btn btn_get btn_get_two" type="submit" onClick={e => this.handleSubscribe(e)}>Subscribe</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s" style={{Visibility: 'visible', AnimationDelay: '0.4s', AnimationName: 'fadeInLeft'}}>
                                <h3 className="f-title f_600 t_color f_size_18">Quick Links</h3>
                                <ul className="list-unstyled f_list">
                                    <li><Link to="/about">About us</Link></li>
                                    <li><Link to="/admissions">Admissions</Link></li>
                                    <li><Link to="/early-years">Early Years</Link></li>
                                    <li><Link to="./docs/registration.pdf" target="_blank" >Download Form</Link></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s" style={{visibility: 'visible', AnimationDelay: '0.6s', animationName: 'fadeInLeft'}}>
                                <h3 className="f-title f_600 t_color f_size_18">Help</h3>
                                <ul className="list-unstyled f_list">
                                <li><Link to="/proprietress">HOS message</Link></li>
                                <li><Link to="/contact">Contact us</Link></li>
                                <li><Link to="/gallery">Gallery</Link></li>
                                <li><a className="text-primary" download href="../../assets/docs/newsletter.pdf">Download newsletter</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s" style={{ visibility: 'visible', AnimationDelay: '0.8s', AnimationName: 'fadeInLeft'}}>
                                <h3 className="f-title f_600 t_color f_size_18">We're social</h3>
                                <div className="f_social_icon">
                                    <a href="https://cobenaschools.com"><FontAwesomeIcon style={{fontSize:'5vh'}} icon={["fab", "facebook"]} /></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https:/instagram.com/cobenaschools"><FontAwesomeIcon style={{fontSize:'5vh'}} icon={["fab", "instagram"]} /></a>
                                    <a href="https://cobenaschools.com"><FontAwesomeIcon style={{fontSize:'5vh'}} icon={["fab", "twitter"]} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bg">
                    <div className="footer_bg_one"></div>
                    <div className="footer_bg_two"></div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-7">
                            <p style={{fontSize: '0.93rem'}} className="mb-0 f_400">© {moment(this.props.dateToFormat).format('YYYY')} | Cobena Schools | All rights reserved | Powered by <a target="_blank" rel="noopener noreferrer" style={{color:'#cc5500'}} href="https://codeflarelimited.com/catalogue">Codeflare</a></p>
                        </div>
                        {/* <div class="col-lg-6 col-sm-5 text-right">
                            <p>Made with <i class="icon_heart"></i> in <a href="#">CakeCounter</a></p>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
            </div>
        )
    }
}

export default Footer;
