import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode, faBookOpen, faCalculator, faFlask, faLanguage, faMusic, faUsers, faFirstAid, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Nav from './header/Nav';
import SideBar from './sidebar/SideBar';
import Footer from './components/Footer';
import Parallax from './components/Parallax';
import {Container} from 'react-bootstrap';
import cobena3 from '../assets/images/cobena3.jpg';
import cobena from '../assets/images/cobena.png';
import abc from '../assets/images/abc.png';
import {Link} from 'react-router-dom'; 
import { faArtstation } from '@fortawesome/free-brands-svg-icons';

class Primary extends Component{
    render(){
        return(
            <div id="App">
            <SideBar />
                <Nav />
                 <header class="showcase" style={{height:'60vh', backgroundImage: `url(${cobena3})`}}>
			<div className="showcase-top">
				<img src={cobena} alt="Cobena early years school" style={{marginTop:'8vh'}} />
			</div>
			<div className="showcase-content">
				<h1>Good Habits Formed As A Child Make All The<span style={{color:'#fff851'}}> Difference.</span></h1>
			</div>
		</header>
        
                <Container>
                <div style={{height:'8vh'}}></div>
                <h2 className="text-danger text-center">Primary Curriculum</h2>
                <center><img src={abc} style={{width:'20vh'}} alt="best primary school in gwarinpa abuja" /></center>
                <div style={{height:'5vh'}}></div>
                <p>
                    Cobena Primary Curriculum is planned in accordance with the British and Nigerian curruculum to enrich the learning experiences of pupils from
                    Year 1-6. The programme we run provide a rich classroom environment where children are encouraged to explore and be challenged to learn, while making
                    friends and developing self-confidence. In order to ensure that everyday is fun for your child, our caring and experienced teachers create activities that
                    combine both learning and fun activities.
                    <p>
                        Our British curruculum is organized into theme-based units, begining with topics familiar to the children. As the children develop their skills and confidence, our
                        teachers address more complex topics. The curruculum include:
                    </p>
                <ul class="list-group text-light">
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Literacy</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faBookOpen }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Numeracy</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faCalculator }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Science</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faFlask }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Creative Arts</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faArtstation }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Modern Foreign Language (MFL)</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faLanguage }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Music</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faMusic }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>ICT</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faLaptopCode }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Social Studies</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faUsers }/></span>
                </li>
                <li class="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>Physical Health Education</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faFirstAid }/></span>
                </li>
                </ul>
                </p>
                <p>
                    Group time is an integral part of a child's day. Here, the children are invited to listen, share, read, dance 
                    and do some group academic work and give feedbacks.
                </p>
                <Link className="btn-alt"  to="./docs/registration.pdf" target="_blank"
					>Download Registration Form <FontAwesomeIcon icon = { faFileDownload }/>
				</Link>
        </Container>
        <div style={{height:'5vh'}}></div>
        <Parallax />
        <Footer />
            </div>
        )
    }
}

export default Primary;