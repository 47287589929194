import React, {Component} from 'react';
import { Container } from 'react-bootstrap';

class ClassBreakDown extends Component{
    render(){
        return(
            <div>
                <div style={{height:'8vh'}}></div>
                <Container>
                <h2 className="text-center">Class Breakdown</h2><br />
                <h4 className="text-dark font-weight-bold">Early years foundation stage</h4>
                <ul className="list-group text-light">
                <li className="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>3 Months - 24 Months: Creche / PlayGroup</p></li>
                <li className="listing list-group-item d-flex justify-content-between align-items-center text-bold" ><p>2 Years - 3 Years: Pre-Nursery</p></li>
                <li className="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>3 Years - 4 Years: Nursery</p></li>
                <li className="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>4 Years - 5 Years: Reception</p></li>
                </ul>
                <div style={{height:'5vh'}}></div>
                <h4 className="text-dark font-weight-bold">Primary class</h4>
                <ul className="list-group text-light">
                <li className="listing list-group-item d-flex justify-content-between align-items-center text-bold"><p>5 years - 6 years: Year 1</p></li>
                </ul> 
                    </Container>
            </div>
        )
    }
}

export default ClassBreakDown;