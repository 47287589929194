import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import school from '../../assets/images/school.png';

class AdColumn extends Component{
    render(){
        return(
            <div style={{marginTop:'5vh'}}>
                <Container>
  <Row>
    <Col sm={4}>
        <img className="img-fluid" style={{width: '45vh'}} src={school} alt="enroll in early years school in abuja" />
    </Col>
    <Col sm={8}>
    <h2>Cobena Schools is a Leading Early Years And Primary School in Abuja</h2>
    <p>At Cobena Schools, we believe that each child is unique and we aim to lovingly
         and skillfully assist each child to become a masterpiece. We provide a sure
         foundation and an integrated curriculum that is broad, balanced, creative and
         relevant to today's world to help students compete both nationally and internationally. ...
    </p>
    <Link to="/early-years" className="btn-alt">Continue Reading ...</Link>
    </Col>
  </Row>
  </Container>
            </div>
        )
    }
}

export default AdColumn;
