import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import kid from '../../assets/images/kid.jpg';


class Enroll extends Component{
    render(){
        return(
            <div style={{marginTop:'15vh'}}>
                 <Container>
  <Row>
  <Col sm={4}>
    <img src={kid} alt="best primary schools in abuja" /> 
    </Col>
    <Col sm={8}>
       <h2>Enroll Your Child in One of the Best Primary Schools in Abuja</h2>
       <p>Cobena Schools is a great place to raise your children and help them develop and realize their full
           potential. Our British and Nigerian curriculum cuts across all vital areas of a child's life, including
            practical, personal, social and emotional development, mathematical skills,  language, cultural as well as 
            expressive arts and design.
       </p>
       <Link to="/primary" className="btn-alt">Enroll Your Child Now</Link>
    </Col>
    </Row>
    </Container>
            </div>

        )
    }
}

export default Enroll;