import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faFlask, faLanguage} from "@fortawesome/free-solid-svg-icons";
import Nav from './header/Nav';
import SideBar from './sidebar/SideBar';
import Footer from '../screens/components/Footer';
import Parallax from '../screens/components/Parallax';
import {Container} from 'react-bootstrap';
import tod from '../assets/images/tod.jpg';
import cobena from '../assets/images/cobena.png';
import teddy from '../assets/images/teddy.png';
 

class Toddler extends Component{
    render(){
        return(
            <div id="App">
            <SideBar />
                <Nav />
                 <header class="showcase" style={{height:'60vh', backgroundImage: `url(${tod})`}}>
			<div className="showcase-top">
				<img src={cobena} alt="Cobena early years school" style={{marginTop:'8vh'}} />
			</div>
			<div className="showcase-content">
				<h1>A Moving Child<span style={{color:'#fff851'}}> Is A Learning Child.</span></h1>
			</div>
		</header>
        <div style={{height:'5vh'}}></div>
        <Container>
                <h1 className="text-danger text-center">Toddlers / Play Group </h1>
                <center><img src={teddy} style={{width:'20vh'}} alt="toddler schools in gwarinpa abuja" /></center>
                <div style={{height:'5vh'}}></div>
                <p>
                    It is a new world when toddlers take to their feet. This is when they walk, talk and begin to develop relationships with each other.
                    Toddlers are great learners. At this stage, they begin to enjoy group play, they communicate their thoughts, etc.
                </p>
                <p>
                    Our toddler program is designed for children between 2-3 years of age. The purpose of the toddler community is to assist the toddler
                    during this special period of growth. It provides children of this age with an opportunity to explore
                    new relationships with friends and to interact with adults in a loving and nurturing environment.
                </p>
                <p>
                    Toddlers are introduced to age-appropriate materials and invited to make choices from a variety of activities that support their developmental
                    interests and needs. Snacks, outdoor play are parts of the daily routine.
                </p>
                <p>
                    The toddler program focuses on three key developmental areas:
                </p>
                <ul class="list-group text-light">
                <li class="list-group-item d-flex justify-content-between align-items-center text-bold" style={{background:'rgb(75,0,130)'}}><p>Sensory And perceptual Development.<br /> This includes
                    any activity that stimulates young child's senses: touch, smell, taste, sight, hearing.<br /> Sensory activities include Sponge painting, Sand Art, Finger Painting, etc.
                </p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faBookOpen }/></span>
                </li>
                {/* <li class="list-group-item d-flex justify-content-between align-items-center text-bold" style={{background:'rgb(75,0,130)'}}><p>Self-help Skills</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faCalculator }/></span>
                </li> */}
                <li class="list-group-item d-flex justify-content-between align-items-center text-bold" style={{background:'rgb(255,127,0)'}}><p>Language Skills / Literacy.<br />
                This helps to turn their ideas into words and sentences through stories, singing, word games and daily conversations with committed and caring teachers.</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faFlask }/></span>
                </li>
                {/* <li class="list-group-item d-flex justify-content-between align-items-center text-bold" style={{background:'rgb(0,255,0)'}}><p>Physical And Motor Skills</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faArtstation }/></span>
                </li> */}
                <li class="list-group-item d-flex justify-content-between align-items-center text-bold text-light" style={{background:'rgb(255,0,0)'}}><p>Social And Emotional Growth. <br />
                We provide group activities to practice and promote personal social, emotional and developmental skills, such as sharing and taking turns, emotions, sorting games, climbing and jumping.</p>
                <span class="badge badge-light badge-pill"><FontAwesomeIcon icon = { faLanguage }/></span>
                </li>
                </ul>
                </Container>
                <div style={{height:'5vh'}}></div>
                <Parallax />
                <Footer />
            </div>
        )
    }
}

export default Toddler;