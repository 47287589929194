import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Nav from './header/Nav';
import SideBar from './sidebar/SideBar';
import Footer from './components/Footer';
import Parallax from './components/Parallax';
import {Container, Row, Col} from 'react-bootstrap';
import early from '../assets/images/early.jpg';
import creche from '../assets/images/creche-pics.jpeg';
import cobena from '../assets/images/cobena.png';
import toddler from '../assets/images/todler.png';
import toddler_happy from '../assets/images/toddlers-happy.jpeg';
import Table from 'react-bootstrap/Table'

class Early extends Component{
    render(){
        return(
            <div id="App">
            <SideBar />
                <Nav />
                 <header class="showcase" style={{height:'60vh', backgroundImage: `url(${early})`}}>
			<div className="showcase-top">
				<img src={cobena} alt="Cobena early years school" style={{marginTop:'8vh'}} />
			</div>
			<div className="showcase-content">
				<h1>Early Years<span style={{color:'#fff851'}}> Foundation.</span></h1>
			</div>
		</header>
        <div style={{height:'5vh'}}></div>
        <Container>
             <h2 className="text-center text-danger">Early Years</h2>
             <center><img className="img-fluid" src={creche} style={{width:'65vh'}} alt="Early years school in gwarinpa abuja" /></center>
             <div style={{height:'5vh'}}></div>
             <p>At Cobena, we provide an integrated curruculum that is broad, balanced, creative and relevant to today's world.
                 Pupils will experience a wide range of activities planned in accordance with the National and Montessori curruculum, setting
                 the standard for learning development.
             </p>
             <p>
                 Every pupil deserves the best possible start and support in order to fulfill their potential. Pupils
                 develop quickly in the early years and a child's experiences between birth and age five (5) have major impact on their future life chances.
             </p>
             <p>
                 A secure, safe and happy childhood is important in its right.
                 Good parenting and high quality early learning together provides the foundation pupils need to make the most of their abilities and talents as they grow.
             </p>
             <p>
                 The Early Years Foundation (EYFS) seeks to provide quality and consistency in all early years settings,
                 so that every pupil makes good progress and no pupil is left behind.
                 A secure foundation through learning and development opportunites which are planned around the needs and interests of each individual
                 and are assessed and reviewed regularly.
             </p>
             <div style={{height:'5vh'}}></div>
             <h2 className="text-center text-danger">7 Areas Of Learning And Development</h2>
             <p>
                 There are seven areas of learning and development that shape our educational programs in early years.
                 All areas of learning and development are important and inter-connected. These areas are as follows:
             </p>

             <Table responsive bordered hover>

             <tbody>
             <tr>
      <td>1</td>
      <td className="text-light font-weight-bold h4">Personal, social and emotional development</td>
      <td><li>Making relationships</li><br />
            <li>Self-confidence and self-awareness</li><br />
            <li>Managing feelings and behaviour.</li>
       </td>
    </tr>
    <tr>
      <td>2</td>
      <td className="text-light font-weight-bold h4">Physical development.</td>
      <td><li>Moving and handling.</li><br />
            <li>Health and self care.</li>
      </td>
    </tr>
    <tr>
      <td>3</td>
      <td className="text-light font-weight-bold h4">Communication and language development</td>
      <td><li>Listening and attention.</li><br />
            <li>Understanding.</li><br />
            <li>Speaking.</li><br />
      </td>
    </tr>
    <tr>
      <td>4</td>
      <td className="text-light font-weight-bold h4">Literacy<br /></td>
      <td><li>Reading.</li> <br />
          <li>Writing.</li><br />
      </td>
    </tr>
    <tr>
      <td>5</td>
      <td className="text-light font-weight-bold h4">Mathematics<br /></td>
      <td><li>Numbers.</li> <br />
          <li>Space, shape and measures.</li><br />
      </td>
    </tr>
    <tr>
      <td>6</td>
      <td className="text-light font-weight-bold h4">Understanding the world<br /></td>
      <td><li>People and Communities.</li><br />
          <li>The World.</li><br />
          <li>Technology.</li>
      </td>
    </tr>
    <tr>
      <td>7</td>
      <td className="text-light font-weight-bold h4">Expressive Arts and Design<br /></td>
      <td><li>Media and Materials.</li><br />
          <li>Imagination.</li>
      </td>
    </tr>
  </tbody>
</Table>
        </Container>
        <div style={{height:'13vh'}}></div>

        <Container>
            <Row>
                {/* TODDLER COLUMN */}
                <Col sm={4}>
                    <img src={toddler} className="img-fluid" alt="toddler schools in abuja" />
                </Col>
                <Col sm={8}>
                    <h2 className="text-danger">Toddlers / Play Group</h2>
                    <p>
                    It is a new world when toddlers take to their feet. This is when they walk, talk and begin to develop relationships with each other. Toddlers are great learners. At this stage, they begin to enjoy group play, they communicate their thoughts, etc.
                    Our toddler program is designed for children between 1-3 years of age. The purpose of the toddler community is to assist the toddler during this special period of growth.
                </p>
                </Col>
                <p className="boot-sm">
                It provides children of this age with an opportunity to explore new relationships with friends and to interact with adults in a loving and nurturing environment.
                    Toddlers are introduced to age-appropriate materials and invited to make choices from a variety of activities that support their developmental interests and needs. Snacks, outdoor play are parts of the daily routine.
                </p>
                </Row>
                <div style={{height:'8vh'}}></div>
                    {/* NURSREY COLUMN */}
                <Row>
                <Col sm={4}>
                    <img src={toddler_happy} className="img-fluid" alt="toddler schools in abuja" />
                </Col>
                <Col sm={8}>
                    <h2 className="text-danger">Pre-nursrey / Nursrey</h2>
                    <p>
                    The children progress from playgroup to pre-nursery, to nursery, explore art materials and experiencing music, dance
                   and drama. Our caring and experienced teachers reinforce needed developmental objectives through creative art activities designed to build relevant
                    skills and these will ignite the passion for learning.
                </p>
                </Col>
                <p className="boot-sm">
                Since children are naturally curious and love to expolre the world around them,
                    we have designed our classrooms to enrich their learning experiences.
                    All these activities and more are focused to develop balance, hand/eye co-ordination, participation in creative movements to music and more.
                    Your child will love our outdoor play areas that are designed for nature study, climbing and sliding.
                </p>
            </Row>
            <Link className="btn-alt"  to="./docs/registration.pdf" target="_blank"
					>Download Registration Form <FontAwesomeIcon icon = { faFileDownload }/>
				</Link>
        </Container>
        <Parallax />
        <Footer />
            </div>

        )
    }
}

export default Early;
