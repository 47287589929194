import React, {Component} from 'react';
import SideBar from './sidebar/SideBar';
import Nav from './header/Nav';
import AdColumn from './components/AdColumn';
import Parallax from './components/Parallax';
import Footer from './components/Footer';
// import founder from '../assets/images/founder.png'
import {Container, Row, Col} from 'react-bootstrap';

class Proprietor extends Component{
    render(){
        return(
            <div id="App">
            <SideBar />
                <Nav />
                <Container style={{marginTop:'15vh'}}>
                    <Row>
                        {/* <Col sm={4}>
                            <img src={founder} alt="Cobena's proprietor" />
                        </Col> */}
                        <Col sm={12}>
                            <h2 className="text-danger">message from the Head of School</h2>
                            <p>
                            I personally welcome you to <strong>COBENA SCHOOLS</strong> with great pleasure and appreciation.<br /><br />
                            Thank you for trusting us to nurture and care for your precious little child(ren).<br />
                            We are pleased to have you as part of the COBENA family. <br /><br />
                            Cobena school started in Lagos, was established to meet the needs of children and encompasses all we stand for and practice.<br /><br />
                              This is so because our vision and mission are focused on providing quality care and building a firm foundation for children.<br/>
                            Cobena is here to watch them grow and develop.<br/><br/>
                            I am proud to tell you that you have made the right choice for the best start any child could experience.<br/><br />
                            <strong className="text-danger">Congratulations!</strong><br /><br />

                            {/* <strong className="text-dark">Lady Uche Okoye</strong><br />
                            <strong className="text-danger">Founder</strong> */}
                    </p>
                        </Col>
                    </Row>
                </Container>
                <div style={{height:'5vh'}}></div>
                <AdColumn />
                <Parallax />
                <Footer />
            </div>
        )
    }
}

export default Proprietor;