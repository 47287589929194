import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import toddler from '../../assets/images/todler.png';


class SchoolAd extends Component {
    render(){
        return(
            <div style={{marginTop:'13vh'}}>
                <Container>
  <Row>
    <Col sm={4}>
        <img src={toddler} alt="toddler schools in abuja" />
    </Col>
    <Col sm={8}>
    <h2>Cobena Schools Has A Leading Program For Toddlers</h2>
    <p>
        Our toddler program is designed for children between 1 to 3 years of age. The purpose of the toddler community is to assist the
        toddler during this special period of growth. It provides children of this age with an opportunity
        to explore new relationships with friends ...
    </p>
    <Link to="/early-years" className="btn-alt">Continue Reading ...</Link>
    </Col>
  </Row>
  </Container>
            </div>
        )
    }
}

export default SchoolAd;
